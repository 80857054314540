import './styles/App.scss';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import ComingSoon from './components/ComingSoon.js';
import Contact from './components/Contact.js';

function App() {
  return (
    <>
      <Header />
      <div className="main">
        <div className="container">
          <ComingSoon />
          {/* <Contact /> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
