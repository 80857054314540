const SpotifyPlaylist = () => (
  <div className='spotify-main'>
    <iframe
      title="Spotify Playlist"
      src="https://open.spotify.com/embed/playlist/417SuTqYaefaB7wYpCkw3B?utm_source=generator"
      className="spotify"
      frameBorder="0"
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    ></iframe>
  </div>
);

export default SpotifyPlaylist;

