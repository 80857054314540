import React from 'react';
import SpotifyPlaylist from './SpotifyPlaylist';
import Image from '../assets/images/coming-soon.png';
import Cross from '../assets/brand-images/cross.png';
import Donations from './Donations.js';

function ComingSoon() {
  return (
    <div className="coming-soon">
      <div className="coming-soon-content">
        <h1>Coming Soon!</h1>
        <img src={Image} alt="Coming Soon!" className="coming-soon-img" />
        <p>
          Welcome to Nazirite Radio. Our mission is to provide you with the most
          recent updates in Christian music and news. While we embrace all
          genres within the Christian music spectrum, we particularly appreciate
          the unique vibes of hip hop and rock.
        </p>

        <h2>For now, engage with us through our social media channels!</h2>
        <div className="social-media-container">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/naziriteradio"
                target="blank"
                className="facebook icon"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/naziriteradio/"
                target="blank"
                className="instagram icon"
              >
                Instagram
              </a>
            </li>

            <li>
              <a
                href="https://twitter.com/NaziriteRadio"
                target="blank"
                className="twitterx icon"
              >
                Twitter X
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@naziriteradio"
                target="blank"
                className="tiktok icon"
              >
                Tik Tok
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCuI791dLc_2V27n3YK4HJBg"
                target="blank"
                className="youtube icon"
              >
                YouTube
              </a>
            </li>
          </ul>
        </div>

        <div className="spotify-container">
            <h2>Check out the Nazirite Radio playlist!</h2>
            <SpotifyPlaylist />
            <p>Visit the Spotify profile for more playlists to suit your taste...</p>
            <a
                href="https://open.spotify.com/user/kw1mvjf3tz4c3k0azfw3j6zzr?si=MGyvUG4KQqCQMhtBTGojbw"
                target="blank"
                className="spotify-icon"
              >
                Spotify
              </a>
         
        </div>
        <div id="donate">
          <Donations />

        </div>

        <div>
          <img
            src={Cross}
            alt="cross with a music note in the center"
            className="cross-img"
          />
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
