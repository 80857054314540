import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <p>Copyright &copy; {currentYear} Nazirite Radio | All Rights Reserved</p>
    </footer>
  );
}

export default Footer;
