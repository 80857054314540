import DonationButton from './DonationsButton.js';

function Donations() {
  return (
    <div className="donations-section">
        <h2>Partner with us!</h2>
        <p>We are thrilled to invite you to partner with us on our new venture dedicated to sharing uplifting Christian music news. Our mission extends beyond the digital realm as we strive to make a positive impact in our community through outreach programs. By joining hands with us, you have the opportunity to contribute to a cause that goes beyond music – a cause that involves reaching out to those in need. Your generous donations will directly support our community outreach initiatives, including efforts to provide food and clothing to the homeless. Together, let's create a harmonious blend of music and compassion, spreading love and making a meaningful difference in the lives of those less fortunate. Partner with us today, and be a part of a movement that aims to bring both melody and warmth to our community.</p>
        
        <DonationButton />


    </div>
  )
}

export default Donations