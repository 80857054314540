import React, { useState } from 'react';
import Logo from '../assets/brand-images/NaziriteRadioAlabaster.png';
import { Link } from 'react-scroll';

function Header() {
  const [isActive, setIsActive] = useState(false);

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false);
  };
  return (
    <nav className="nav" id="nav">
      <div className="nav-container">
        <div className="logo-container">
          <Link
            className="logo"
            onClick={removeActive}
            activeClass="active"
            to="header"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <img
              src={Logo}
              alt="text that says Nazirite Radio"
              className="logo"
            />
          </Link>
        </div>


        {/* <ul className={`nav-list ${isActive ? 'active' : ''}`}>
          <li className="animated scaleCenter">
            <Link
              onClick={removeActive}
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <span>About</span>
            </Link>
          </li>
          <li>
            <Link
              className="animated scaleCenter"
              onClick={removeActive}
              activeClass="active"
              to="projects"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <span>Projects</span>
            </Link>
          </li>

          <li>
            <Link
              className="animated scaleCenter"
              onClick={removeActive}
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <span>Contact</span>
            </Link>
          </li>
        </ul> */}
      </div>

      {/* <div
        className={`hamburger ${isActive ? 'active' : ''}`}
        onClick={toggleActiveClass}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div> */}
    </nav>
  );
}

export default Header;
